import styled from "styled-components";

export const Terms = () => (
  <StyledPage>
    <div className="title">УГОДА КОРИСТУВАЧА</div>
    <p>
      Програма для ЕОМ “House Hunter” (далі - HH ) надає свої функціональні
      можливості на платній основі. Розпочавши використовувати HH, ви
      погоджуєтеся з правилами викладеними в Угоді користувача.
    </p>
    <p>
      Цей документ (далі – «Угода користувача») є пропозицією HH укласти Угоду
      про використання програми HH на умовах, визначених нижче.
    </p>
    <div className="subtitle">1. ЗАГАЛЬНІ ПРАВИЛА</div>
    <p>
      1.1. Ця Угода користувача (далі – Угода) визначає загальні умови та умови
      використання програмного забезпечення HH на Інтернет-сайті за
      адресою: https://house-hunter.info/   (далі – HH ), а також права та
      обов’язки Користувача та Ліцензіара. Ця угода також стосується прав та
      інтересів третіх осіб, окрім Користувачів HH , але на права та інтереси
      яких можуть впливати дії Користувачів HH.
    </p>
    <p>
      1.2. Ця угода є угодою між Користувачем і Ліцензіаром щодо
      використання HH  та його послуг і заміняє всі попередні угоди, які мали
      місце або можуть мати місце між Користувачем і Ліцензіаром незалежно від
      форми, де були досягнуті такі домовленості.
    </p>
    <p>
      Використання HH  на вільній основі допускається у випадках, визначених
      Ліцензіаром. Ліцензіар має право вводити і (або) накладати будь-які
      обмеження на функціональні можливості HH, що використовується безкоштовно.
    </p>
    <p>
      1.3. Ця угода є юридично обов’язковою угодою між Користувачем і
      Ліцензіаром, предметом якої є надання Ліцензіаром Користувачу права
      використання на умовах простих (невиключних) ліцензій HH  відповідно до
      його функціональних можливостей, в тій мірі, що визначається Ліцензіаром,
      а також надання інших пов'язаних послуг та інших послуг (далі - послуги).
      Крім цієї Угоди, Угода між Користувачем і Ліцензіаром включає всі інші
      обов’язкові документи, що регламентують надання послуг, і розміщені у
      відповідних розділах HH.
    </p>
    <p>
      1.4. Користувач повинен бути повністю ознайомлений з цією Угодою, перш ніж
      зареєструватися в HH. Реєстрація Користувача в HH  означає повне і
      безумовне прийняття Користувачем цієї Угоди Користувача.
    </p>
    <p>
      1.5. Ця Угода може бути змінена і (або) доповнена Ліцензіаром в
      односторонньому порядку без будь-якого спеціального повідомлення
      Користувача. Ця Угода є публічним документом.
    </p>
    <p>
      1.6. Користувач регулярно перевіряє умови цієї Угоди на їх зміну та (або)
      доповнення. Продовження використання HH  Користувачем після внесення змін
      та (або) доповнень до цієї Угоди означає прийняття та згоду Користувача з
      усіма такими змінами та (або) доповненнями.
    </p>
    <p>
      1.7. Жодні положення цієї Угоди не надають Користувачу права на
      використання назви бренду, торгових марок, доменних імен та
      інтелектуальної власності, що використовуються HH , за винятком випадків,
      коли таке використання дозволено з письмовою та попередньою згодою
      Ліцензіара.
    </p>
    <p>
      1.8. Під час реєстрації Користувач погоджується з цією Угодою і бере на
      себе зазначені в ній права та обов'язки, пов'язані з використанням та
      експлуатацією HH .
    </p>
    <p>
      1.9. Приймаючи цю Угоду, Користувач підтверджує свою згоду на
      обробку HH  персональних даних Користувача, наданих Користувачем на момент
      реєстрації, а також добровільно розміщених Користувачем в HH.
    </p>
    <p>
      1.10. Користувач має право укласти з Ліцензіаром окремий ліцензійний
      договір на використання HH без обмежень, встановлених для
      використання HH у безкоштовній версії.
    </p>
    <div className="subtitle">2. ОСНОВНІ ТЕРМІНИ</div>
    <p>
      До цього документу та всіх відносин сторін, що виникають з цього
      документу, застосовуються такі терміни та визначення:
    </p>
    <p>
      2.1. Угода означає угоду між HH  і Користувачем, яка виконується, в
      порядку і відповідно до умов цієї Угоди Користувача.
    </p>
    <p>
      2.2. Програма є програмою для комп'ютера «HH » як в цілому, так і її
      компоненти, представлені в автомобілівній формі набором даних і команд,
      включаючи вихідний текст, бази даних, аудіовізуальні роботи, включені
      Ліцензіаром в Програму, а також будь-яка документація про її використання.
    </p>
    <p>
      2.3. Використання програми  - використання функціональних можливостей та
      (або) відтворення однієї копії Програми шляхом її встановлення та (або)
      запуску Користувачем у спосіб, визначений (технічною) документацією,
      Ліцензійним договором та цією Угодою.
    </p>
    <p>
      2.4. Ліцензіар - фізична особа підприємець Мицавка Юрій Юрійович, Україна,
      82100 м. Дрогобич, вул. Горішня Брама 74. КОД РНОКПП:
      3371613857. Ліцензіат гарантує, що він має всі необхідні права та
      повноваження для надання ліцензії та виконання своїх зобов'язань за цією
      Угодою, повноваження на продаж та розповсюдження продукції HH на території
      України.
    </p>
    <p>
      2.5. Ліцензіат - будь-яка фізична або юридична особа, яка має право
      користуватися Програмою HH  в своїх інтересах відповідно до вимог
      законодавства, Ліцензійного договору та цієї Угоди.
    </p>
    <p>
      2.6. Ліцензійний договір  - документ, на підставі якого Ліцензіар (або
      інша особа, що має відповідні права) надає Ліцензіату Програму для її
      використання за умовами цієї Угоди.
    </p>
    <p>
      2.7. Тип ліцензії - тарифний план, обраний Ліцензіатом зі списку,
      розміщеного Ліцензіаром в особистому кабінеті користувача на
      Інтернет-сторінці: https://house-hunter.info/company . 
    </p>
    <p>
      2.8. Користувач  - фізична особа, яка від імені та (або) з дозволу
      Ліцензіата здійснює фактичне використання Програми; будь-яка особа, яка
      висловила бажання користуватися послугами, наданими Ліцензіаром, готує
      заяву про намір користуватися цими послугами. Якщо Ви фізична особа, що
      діє від імені корпорації/мережі або іншої юридичної особи, фізичної
      особи-підприємця, Ви підтверджуєте, що маєте повноваження погоджуватися на
      цю Угоду користувача від імені юридичної особи.
    </p>
    <p>
      Якщо ви не згодні з цією Угодою Користувача, ви не можете отримати доступ
      або скористатися Програмою, сайтом або будь-якими матеріалами,
      документацією, інформацією, що належить HH  або будь-яким третім особам.
    </p>
    <p>
      2.9. Уповноважений користувач – Користувач, зареєстрований Ліцензіатом у
      Програмі, і авторизувався у ній не рідше одного разу.
    </p>
    <p>
      2.10. Обліковий запис користувача - запис в HH  і (або) в Програмі,
      зберігаючи дані, що дозволяють ідентифікувати та авторизувати Ліцензіата,
      як Користувача Програми.
    </p>
    <p>
      2.11. Реєстрація - дія, спрямована на створення облікового запису
      користувача. Реєстрація Користувача в HH  безкоштовна і добровільна. При
      реєстрації в HH  Користувач зобов'язаний забезпечити Ліцензіара необхідною
      достовірною і актуальною інформацією. Користувач несе відповідальність за
      точність, актуальність, повноту і відповідність інформації, що надається
      при реєстрації та її чистоту від претензій третіх осіб. Розділ може бути
      заповнений фізичною особою, фізичною особою-підприємцем або юридичною
      особою.
    </p>
    <p>
      2.12. Авторизація  - дозвіл, наданий Ліцензіаром на доступ до облікового
      запису Користувача, який реалізується шляхом аналізу інформації про
      обліковий запис у вигляді унікальної комбінації імені Користувача і
      пароля, введеної Користувачем на сторінці авторизації HH, і перевірки, що
      вона відповідає інформації, яка міститься в обліковому записі Користувача.
    </p>
    <p>
      2.13. Особистий кабінет користувача -  спеціалізований приватний
      розділ HH , який доступний Уповноваженому користувачу після реєстрації та
      авторизації, і призначений для здійснення Користувачем управління HH  та
      його налаштування. Для входу в особистий кабінет Користувача
      використовується унікальне імʼя Користувача та пароль, отриманий
      Користувачем під час Реєстрації відповідно до пункту 8.4. цієї Угоди.
    </p>
    <p>
      2.14. Персональна сторінка користувача - спеціальний окремий статистичний
      розділ HH , що містить інформацію про Ліцензіата та його діяльність,
      включаючи інформацію про послуги та (або) ціни на послуги, що надаються
      Ліцензіатом, фото та відео, контактні дані Ліцензіата. Відбір інформації,
      розміщеної Ліцензіатом на Персональній сторінці Користувача, здійснюється
      Ліцензіатом самостійно і не підлягає попередньому узгодженню з
      Ліцензіаром.
    </p>
    <p>
      2.15. Служба запису - набір апаратних та програмних засобів HH , що
      відображають інформацію в реальному часі про осіб, які виявили бажання
      користуватися послугами, наданими Ліцензіатом в HH.
    </p>
    <p>
      2.16. Акція - рекламна акція, що проводиться Ліцензіатом з метою
      просування своїх послуг за допомогою HH.
    </p>
    <p>
      2.17. Клієнтська база  - структурована інформація про осіб, які виявили
      бажання користуватися послугами, наданими Ліцензіатом, а також інформація
      про споживання послуг, що надаються Ліцензіатом, які були введені
      Користувачем в HH.
    </p>
    <p>
      2.18. Тарифікація - розділ особистого кабінету Користувача, що містить
      інформацію про термін дії ліцензії, тарифи та обсяг послуг, які доступні
      Користувачеві.
    </p>
    <div className="subtitle">3. ПРЕДМЕТ УГОДИ</div>
    <p>
      3.1. Ліцензіар надає Ліцензіату право користування (простою (невиключною)
      ліцензією) Програмою в межах її функціональних можливостей шляхом
      відтворення Програми (підключення до Програми через мережу Інтернет)
      виключно для власного використання Ліцензіатом без права Ліцензування
      третім особам.
    </p>
    <p>
      3.2. Ця Угода укладається до або одразу після початку використання
      Програми і залишається чинною на весь період її законного використання
      Ліцензіатом у терміни дії авторського права на неї, за умови дотримання
      Ліцензіатом цієї Угоди.
    </p>
    <p>
      3.3. Ця Угода і передбачені в ній обов'язкові документи визначають основні
      умови використання програми.
    </p>
    <p>
      3.3.1. Обов'язковою умовою використання програми включно з усіма послугами
      є повне та безумовне прийняття Користувачем умов наступних документів
      (далі - «обов'язкові документи»):
    </p>
    <p>
      <ul>
        <li>
          Політика конфіденційності, яка розміщена та доступна в мережі Інтернет
          за адресою: 
          <a target="_blank" href="https://house-hunter.info/info/privacy">
            https://house-hunter.info/info/privacy"
          </a>
            та встановлює правила обробки персональної інформації, включаючи
          персональні дані Користувача.
        </li>
        <li>
          Публічна пропозиція укласти Ліцензійний договір, розміщена та/або
          доступна в мережі Інтернет на сайті: 
          <a target="_blank" href="https://house-hunter.info/info/license">
            https://house-hunter.info/info/license
          </a>
           . 
        </li>
      </ul>
    </p>
    <p>3.3.3. Користувач визнає наступне:</p>
    <ul>
      <li>
        Ви переглянули умови цієї Угоди і обов'язкових документів, передбачених
        ній, в повному обсязі перед використанням Програми.
      </li>
      <li>
        Ви приймаєте умови Угоди і всіх обов'язкових документів, передбачених в
        ній, в повному обсязі без будь-яких винятків і обмежень з вашого боку і
        зобов'язані дотримуватися їх або припинити використання Програми. Якщо
        ви не погоджуєтеся з цією Угодою і обов'язковими документами,
        передбаченим у ній, або не маєте права на їх підставі укладати Угоду,
        вам слід негайно припинити використання програми.
      </li>
      <li>
        Поточна версія цієї Угоди опублікована за адресою: 
        <a target="_blank" href="https://house-hunter.info/info/terms">
          https://house-hunter.info/info/terms
        </a>
         . 
      </li>
    </ul>
    <p>
      3.4. Ліцензіар надає Ліцензіату право користуватися Програмою без
      обмеження на території в порядку та в умовах, передбачених законодавством,
      Ліцензійним договором та цією Угодою.
    </p>
    <p>
      3.5. Розмір, порядок та умови виплати Ліцензіатом ліцензійного платежу за
      користування Програмою встановлюються Ліцензійним договором.
    </p>
    <div className="subtitle">4. АВТОРСЬКІ ПРАВА ТА ТОВАРНІ ЗНАКИ</div>
    <p>
      4.1. Програма є результатом інтелектуальної діяльності та автомобільом
      авторського права (Програма для ЕОМ), які регулюються та охороняються
      законодавством про інтелектуальну власність та нормами міжнародного права.
    </p>
    <p>
      4.2. Алгоритми роботи Програми та її вихідні коди (включаючи їх частини) є
      комерційною таємницею Ліцензіара. Будь-яке їх використання або
      використання програмного забезпечення з порушенням умов цієї Угоди та
      (або) Ліцензійного договору розглядається як порушення прав Ліцензіара і
      має бути достатньою підставою для позбавлення Користувача прав, наданих за
      цією Угодою.
    </p>
    <p>
      4.3. Ця Угода не надає Ліцензіату права власності на Програму та її
      складові, а лише право на використання Програми та її складових відповідно
      до умов, визначених цією Угодою та Ліцензійним договором.
    </p>
    <p>
      4.4. Ліцензіар гарантує, що він має всі необхідні права на Програму для
      надання її Ліцензіату, включаючи документацію на Програму.
    </p>
    <p>
      4.5. Ця Угода не надає Ліцензіату жодних прав на використання товарних
      знаків та знаків обслуговування Ліцензіара та Ліцензіара та (або) його
      партнерів.
    </p>
    <p>
      4.6. За жодних обставин Ліцензіат не може видаляти або вносити незначні
      відомості про авторські права, права на товарний знак, знаки
      обслуговування, патенти, вказані у Програмі.
    </p>
    <p>
      4.7. Ліцензіат не може копіювати, розповсюджувати Програму та її
      компоненти у будь-якій формі, у тому числі у вигляді вихідного коду, у
      тому числі надавати в оренду, передавати для безоплатного користування.
    </p>
    <p>
      4.8. Ліцензіат не може жодним чином використовувати Програму, якщо це
      суперечить або призводить до порушення цієї Угоди, Ліцензійного договору
      або вимог національного та міжнародного законодавства.
    </p>
    <p>
      4.9. Відповідальність за порушення авторських прав Ліцензіат несе у
      відповідності до національного та міжнародного законодавства.
    </p>
    <div className="subtitle">5. УМОВИ ВИКОРИСТАННЯ ПРОГРАМИ ТА ОБМЕЖЕННЯ</div>
    <p>5.1. Ліцензіат має право Реєстрації в рамках цієї Угоди.</p>
    <p>
      5.2. Ліцензіар не надає послуг зв'язку Ліцензіату, не організовує для
      нього можливості доступу до інформаційних систем
      інформаційно-телекомунікаційних мереж, у тому числі мережі Інтернет, і не
      здійснює діяльності з прийому, обробки, зберігання, передачі, доставки
      телекомунікаційних повідомлень.
    </p>
    <p>
      5.3. Ліцензіат має право в рамках цієї Угоди, Ліцензійного договору та
      відповідно до обраного типу ліцензії розмістити в Програмі належні йому
      дані, якщо це не порушує цю Угоду, Ліцензійний договір, національне та
      (або) міжнародне законодавство.
    </p>
    <p>
      5.4. Ліцензіат може змінювати, додавати або видаляти файли Програми лише у
      випадках, передбачених законодавством про авторське право.
    </p>
    <p>
      5.5. Ліцензіат не може жодним чином використовувати Програму, якщо таке
      використання суперечить або призводить до порушення законодавства.
    </p>
    <p>
      5.6. У разі невиконання будь-якого з положень цієї Угоди, Ліцензіат
      втрачає право на оновлення програмного забезпечення без будь-якого
      повідомлення від Ліцензіара.
    </p>
    <div className="subtitle">6. УСТУПКА (ПЕРЕДАЧА) ПРАВ НА ПРОГРАМУ</div>
    <p>
      <b>
        7.1. Ліцензіат має право використовувати Програму в рамках ліцензії для
        ознайомлення (пробної версії) протягом обмеженого періоду часу,
        визначеного умовами чинними на момент реєстрації, встановленими
        Ліцензіаром та розміщеними в мережі Інтернет на веб-сайті: 
        <a target="_blank" href="https://house-hunter.info/company">
          https://house-hunter.info/company
        </a>
          . 
      </b>
    </p>
    <p>7.2. Ліцензіат має право вибрати відповідний тип ліцензії зі списку.</p>
    <p>
      7.3. Тип ліцензії, яку обирає Ліцензіат, визначається Ліцензійним
      договором.
    </p>
    <p>
      7.4. Термін дії ліцензії, визначений відповідно до типу ліцензії, починає
      діяти з наступного дня після прийняття Ліцензійного договору.
    </p>
    <p>
      7.5. Ліцензіат має право у будь-який час змінити тип ліцензії протягом
      строку дії ліцензії, визначений Ліцензійним договором. При цьому:
    </p>
    <p>
      7.5.1. Строк дії придбаної ліцензії буде збільшено на період платної, але
      невитраченої, поточної ліцензії з урахуванням тарифної вартості нової
      ліцензії.
    </p>
    <p>
      7.5.2. Перерахування вартості та відшкодування ліцензійного платежу,
      сплаченого згідно з Ліцензійним договором, при купівлі поточної ліцензії
      не здійснюється.
    </p>
    <p>
      7.6. Після закінчення терміну дії ліцензії Ліцензіат може обмежити
      подальше використання Програми Ліцензіатом, а обліковий запис Користувача
      може бути заблокований.
    </p>
    <p>
      7.7 Ліцензіар має право в односторонньому порядку змінювати типи ліцензій
      шляхом розміщення нового їх списку на
      Інтернет-сторінці: https://house-hunter.info/company  . Типи ліцензій
      застосовуються в Ліцензійних договорах, підписаних, починаючи з дати
      публікації відповідної (модифікованої) інформації про типи ліцензій на
      вищевказаній сторінці в мережі Інтернет. Модифікація типів ліцензій у
      вказаному порядку не є підставою для внесення змін до Ліцензійних
      договорів, укладених до дати публікації Ліцензіаром інформації про зміну
      видів ліцензії.
    </p>
    <div className="subtitle">8. ВИКОРИСТАННЯ ПРОГРАМИ</div>
    <p>8.1. Загальні умови використання Програми:</p>
    <p>
      8.1.1. Перегляд матеріалів, розміщених на веб-сайті в публічному домені,
      не вимагає реєстрації та/або авторизації Користувача.
    </p>
    <p>
      8.1.2. Будь-яке інше використання функціональних можливостей Програми,
      включаючи надання послуг, дозволяється тільки після реєстрації та
      авторизації Користувача в Програмі відповідно до правил, визначених HH.
    </p>
    <p>
      8.1.3. Діапазон функціональних можливостей Програми, використання якої
      вимагає попередньої реєстрації та/або авторизації, а також виконання
      окремої угоди та виплати ліцензійного платежу, визначається на розсуд HH і
      може час від часу змінюватися.
    </p>
    <p>8.2. Реєстрація.</p>
    <p>
      8.2.1. Для того, щоб скористатися Програмою Ліцензіату, необхідно виконати
      процедуру реєстрації, яка створить унікальний обліковий запис Користувача
      для Ліцензіата.
    </p>
    <p>
      8.2.2. Реєстрація здійснюється Ліцензіатом самостійно шляхом заповнення
      реєстраційної форми на сайті  
      <a target="_blank" href="https://house-hunter.info/">
        https://house-hunter.info/
      </a>
       .
    </p>
    <p>8.2.3. Реєстрація в HH є безкоштовною та добровільною.</p>
    <p>
      8.2.4. При реєстрації в HH Користувач зобов'язаний надати Ліцензіару
      необхідну достовірну та актуальну інформацію.
    </p>
    <p>
      8.2.5. Користувач не має права здійснювати Реєстрацію від імені або
      замість іншої особи, за винятком випадку, коли Користувач має необхідні
      повноваження, надані у порядку, встановленому законодавством, щодо надання
      йому можливості здійснювати Реєстрацію від імені третьої особи.
    </p>
    <p>
      8.2.6. Користувач несе відповідальність за точність, актуальність, повноту
      та відповідність законодавству інформації наданої при реєстрації та її
      чистоту від претензій третіх осіб. Якщо Користувач надає неправильну
      інформацію, або якщо Ліцензіар має підстави вважати, що інформація, надана
      Користувачем, є неповною або ненадійною, то Ліцензіар має право блокувати
      або видалити обліковий запис Користувача, створений Ліцензіатом, і
      заборонити Ліцензіату використовувати Програму.
    </p>
    <p>
      8.2.7. HH  залишає за собою право вимагати в будь-який час перевірки
      інформації Користувача, що надається при реєстрації, та запиту на надання
      для перевірки документів (зокрема документів особистої ідентифікації);
      ненадання цих документів може, на розсуд інших користувачів, розглядатися
      як надання недійсної інформації та тягнути за собою наслідки передбачені
      цією Угодою. У випадку, якщо інформація про Користувача, вказана в наданих
      ним документах, не відповідає інформації, що надається ним при реєстрації,
      або якщо інформація, надана при реєстрації, не дає можливості
      ідентифікувати Користувача, HH залишає за собою право відмовити в доступі
      Користувача до персонального облікового запису Користувача та у
      використанні Програми.
    </p>
    <p>
      8.2.8. Інформація про Користувача, що міститься в особистому кабінеті
      Користувача, зберігається і обробляється HH  відповідно до політики
      конфіденційності https://house-hunter.info/info/privacy . 
    </p>
    <p>
      8.2.9. Користувач самостійно визначає процедуру використання облікового
      запису та інші функціональні можливості Програми, включаючи послуги, які,
      однак, ні за яких обставин не можуть суперечити цій Угоді.
    </p>
    <p>
      8.2.10. Після реєстрації Користувач має право користуватися Програмою та
      користуватися послугами на умовах простої (невиключної) ліцензії в тій
      мірі, в якій визначається Ліцензіаром та Ліцензійним договором.
    </p>
    <p>
      8.3. Щоб додати Уповноважених користувачів, необхідно зареєструвати їх у
      обліковому записі Користувача, заповнивши відповідну форму. Кількість
      Уповноважених користувачів визначається на основі типу ліцензії.
    </p>
    <p>
      8.4. Після реєстрації Ліцензіар надсилає Ліцензіату на адресу електронної
      пошти та (або) номер мобільного телефону (через SMS) ім'я Користувача та
      пароль, які може бути використано Користувачем під час авторизації. Надалі
      Користувач має право самостійно змінювати ім'я Користувача і (або) пароль,
      використовуючи відповідний інтерфейс Програми. У цьому випадку Користувач
      несе відповідальність за безпеку (опір вгадування) обраного імені
      Користувача і (або) пароля.
    </p>
    <p>
      8.4.1. Користувач не має права передавати ім'я Користувача і пароль,
      отриманий від Ліцензіара під час реєстрації, або змінений самостійно за
      допомогою відповідного інтерфейсу Програми третім особам.
    </p>
    <p>
      8.4.2. Користувач повністю відповідає за безпеку і конфіденційність імені
      і пароля Користувача, вибираючи спосіб їх зберігання. Користувач
      апаратного програмного забезпечення, який він використовує, може дозволити
      зберігання імені Користувача і (або) пароля (включаючи cookies) для
      подальшої автоматичної авторизації в HH.
    </p>
    <p>
      8.4.3. Якщо Користувач не доведе іншого, будь-які дії, виконані за
      допомогою отриманих від Ліцензіара, під час реєстрації, або змінені
      самостійно у відповідному інтерфейсі Програми ім'я Користувача та пароль,
      вважаються діями відповідного Користувача. У разі несанкціонованого
      доступу до вказаного імені Користувача і пароля та (або) особистого
      кабінету Користувача та (або) облікового запису Користувача, а також у
      випадку розповсюдження вказаного імені Користувача і пароля, Користувач
      негайно повідомляє Ліцензіара про будь-які порушення або підозри в
      порушенні їх конфіденційності. Дії в Програмі, що відбуваються після того,
      як Ліцензіар отримує таке повідомлення, не визнаються вчиненими
      Користувачем.
    </p>
    <p>
      8.4.4. Ліцензіар має право накладати обмеження (включаючи заборони) на
      використання певних імен Користувачів, а також встановлювати вимоги до
      імені Користувача і пароля (довжини, дозволених символів тощо).
    </p>
    <p>
      8.5. З міркувань безпеки Користувач зобов'язаний здійснити безпечне
      завершення роботи під своїм обліковим записом Користувача (кнопка «вихід»)
      в кінці кожного сеансу роботи з Програмою. Ліцензіар не несе
      відповідальності за можливу втрату даних, а також будь-які інші наслідки,
      які можуть виникнути через порушення Користувачем положень цієї частини
      цієї Угоди.
    </p>
    <p>
      8.6. Користувач повністю відповідає за свої дії, пов'язані з
      використанням HH  і послуг, відповідно до законодавства. Якщо Ліцензіар
      виявить, що Користувач порушив цю Угоду, національне чи міжнародне
      законодавство, що регулює використання Програми, Ліцензійний договір, то
      Ліцензіар має право блокувати доступ Користувача до HH.
    </p>
    <p>8.7. Під час користування Програмою та послугами Користувач повинен:</p>
    <p>
      8.7.1. Дотримуватися положень законодавства, цієї Угоди, Ліцензійного
      договору та інших обов’язкових документів, розміщених Ліцензіаром в HH.
    </p>
    <p>
      8.7.2. Використовувати Програми, послуги тільки з законною метою та
      законними шляхами, з урахуванням вимог законодавства.
    </p>
    <p>
      8.7.3. Не розміщувати в HH інформацію та автомобілі (включаючи посилання
      на них), які можуть порушувати права та інтереси інших осіб.
    </p>
    <p>
      8.7.4. Зберігати конфіденційність і не ділитися з іншими Користувачами або
      третіми особами будь-якими особистими даними, які їм розголошуються в
      результаті спілкування з іншими Користувачами або іншого
      використання HH  (в тому числі, але не обмежуючись, адресами в адресній
      системі, номерами телефонів, адресами електронної пошти, номерами ICQ,
      даними паспортів, банківською інформацією тощо) та інформацію про
      конфіденційність інших Користувачів та третіх осіб без попереднього
      письмового дозволу такого Користувача.
    </p>
    <p>
      8.7.5. Переконатися, що адреса електронної пошти та (або) номер телефону,
      який використовується як ім'я Користувача при авторизації, є актуальним.
    </p>
    <p>
      8.8. Під час користування Програмою та послугами Користувачу
      забороняється:
    </p>
    <p>
      8.8.1. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      містить погрози, дискредитації, образи, порушення честі та гідності або
      ділової репутації, або порушує приватність інших Користувачів або третіх
      осіб.
    </p>
    <p>
      8.8.2. Скачати, зберігати, публікувати, поширювати і надавати доступ або в
      іншому випадку використовувати будь-яку інформацію, що порушує права
      неповнолітніх.
    </p>
    <p>
      8.8.3. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію,
      вульгарного чи непристойного характеру, містить нецензурну лексику,
      містить порнографічні зображення та тексти, містить сексуальні сцени за
      участю неповнолітніх.
    </p>
    <p>
      8.8.4. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      містить сцени насильства, включаючи нелюдські поводження з тваринами.
    </p>
    <p>
      8.8.5. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      містить опис засобів та методів самогубства, будь-яке підбурювання до
      самогубства.
    </p>
    <p>
      8.8.6. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      містить пропаганду та (або) сприяє расовій, релігійній, етнічній ненависті
      або ворожнечі, пропагує фашизм або ідеологію расової переваги.
    </p>
    <p>
      8.8.7. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      містить екстремістські матеріали.
    </p>
    <p>
      8.8.8. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      сприяє злочинній діяльності або містить поради, інструкції або вказівки
      щодо вчинення злочинних дій.
    </p>
    <p>
      8.8.9. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      містить інформацію з обмеженим доступом, включаючи, але не обмежуючись,
      державну і комерційну таємниці, інформацію про приватне життя третіх осіб.
    </p>
    <p>
      8.8.10. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      містить рекламу або описує привабливість вживання наркотиків, включаючи
      звукові файли, інформацію про розповсюдження таких речовин (включаючи
      звукові файли), рецепти їх виготовлення та поради щодо споживання.
    </p>
    <p>
      8.8.11. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, що має
      характер шахрайських дій.
    </p>
    <p>
      8.8.12. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати будь-яку інформацію, яка
      порушує інші права та інтереси громадян і юридичних осіб або вимоги
      законодавства.
    </p>
    <p>
      8.8.13. Незаконно завантажувати, зберігати, публікувати, розповсюджувати
      та надавати доступ до інтелектуальної власності Ліцензіара, Користувачів,
      третіх осіб.
    </p>
    <p>
      8.8.14. Розміщення будь-якої іншої інформації, яку Ліцензіар вважає
      небажаною, яка не відповідає цілям використання HH , упередженою до
      інтересів інших Користувачів, або іншим чином є небажаною для розміщення
      в HH.
    </p>
    <p>
      8.8.15. Використовувати програмне забезпечення та виконувати дії,
      спрямовані на припинення нормального функціонування HH, його служб,
      послуг.
    </p>
    <p>
      8.8.16. Завантажувати, зберігати, публікувати, розповсюджувати та надавати
      доступ або в іншому випадку використовувати віруси, трояни та інші
      шкідливі програми.
    </p>
    <p>
      8.8.17. Використовувати, без спеціального письмового та попереднього
      дозволу Ліцензіара, автоматизовані скрипти (програми) для збору інформації
      в HH  та (або) для взаємодії з HH, його послугами, службами.
    </p>
    <p>
      8.8.18. У будь-якому випадку, включаючи, але не обмежуючись, обманом,
      зловживанням довірою, зломом, спробами отримати доступ до імені
      Користувача і пароля іншого облікового запису.
    </p>
    <p>
      8.8.19. Збирати та обробляти персональні дані інших осіб незаконно (у тому
      числі з порушенням правил, встановлених законодавством).
    </p>
    <p>
      8.8.20. Доступ (у тому числі спроба доступу) до будь-якої іншої послуги,
      ніж через HH  (інтерфейс, що надається Ліцензіаром), за виключенням
      випадків, коли такі дії були прямо і попередньо дозволені Користувачу
      відповідно до Ліцензійного договору або іншою окремою угодою з
      Ліцензіаром.
    </p>
    <p>
      8.8.21. Відтворення, дублювання, копіювання, продаж, торгівля та
      перепродаж послуг з будь-якою метою, за винятком випадків, коли такі дії
      були прямо та попередньо погоджені Користувачем відповідно до Ліцензійного
      договору або іншою окремою угодою з Ліцензіаром.
    </p>
    <p>
      8.8.22. Використовувати Програму, HH , послуги для здійснення масової
      розсилки електронних та інших повідомлень комерційного, рекламного та
      іншого характеру, не узгоджених (не запитних) одержувачем інформації. Ця
      заборона не поширюється на надсилання інформації зі згоди одержувача, якщо
      можливо відмовитися від отримання такої інформації в майбутньому.
    </p>
    <p>
      8.9. Користувач несе відповідальність за будь-яку інформацію, яку він
      розміщує в Програмі, повідомляє іншим Користувачам і третім особам, а
      також за будь-яку взаємодію з іншими Користувачами і третіми особами.
    </p>
    <p>
      8.10. Користувач несе відповідальність за власні дії у зв'язку зі
      створенням та розміщенням інформації в HH, відповідно до законодавства.
    </p>
    <p>
      8.11 Ліцензіар не несе відповідальності за порушення Користувачем цієї
      Угоди і залишає за собою право на власний розсуд, а також при отриманні
      від інших Користувачів або третіх осіб інформації про порушення
      Користувачем цієї Угоди, змінювати (модерувати), та (або) видаляти
      будь-яку інформацію, опубліковану Користувачем, що порушує заборони,
      встановлені цією Угодою, призупинити, обмежити або припинити доступ
      Користувача до всіх або будь-яких розділів або служб у HH  у будь-який час
      з будь-яких причин та без пояснення причин, з або без попереднього
      повідомлення, не несе відповідальності за будь-яку шкоду, яка може бути
      заподіяна Користувачу такими діями.
    </p>
    <p>
      8.12. Ліцензіар має право без пояснення причин призупинити, обмежити або
      припинити доступ Користувача до HH  або будь-якої служби. Ліцензіар не
      несе відповідальності за тимчасове блокування або видалення інформації або
      видалення облікового запису Користувача відповідно до цієї Угоди.
    </p>
    <p>8.13. Видалення облікового запису Користувача.</p>
    <p>
      8.13.1. Ліцензіар має право блокувати та вилучати обліковий запис
      Користувача Ліцензіата, включаючи весь контент, без пояснення причин, у
      разі порушення умов цієї Угоди. З цього моменту неможливо відновити
      обліковий запис, будь-яку інформацію, пов'язану з ним, або отримати доступ
      до Програми за допомогою віддаленого облікового запису.
    </p>
    <p>
      8.13.2. У разі незгоди Користувача з цією Угодою або її поновлення
      відповідно до порядку, передбаченого цією Угодою, Користувач зобов'язаний
      відмовитися від використання Програми, повідомивши про це Ліцензіара
      будь-якими доступними засобами. Після отримання зазначеного повідомлення,
      Ліцензіар має право заблокувати і (або) видалити обліковий запис
      Користувача Ліцензіата, включаючи весь опублікований ним контент. З цього
      моменту неможливо відновити обліковий запис, будь-яку інформацію,
      пов'язану з ним, або отримати доступ до Програми за допомогою віддаленого
      облікового запису.
    </p>
    <p>
      8.14. Ліцензіар залишає за собою право в будь-який час змінювати
      дизайн HH , його зміст, список послуг і служб, змінювати або доповнювати
      скрипти, програмне забезпечення та інші автомобілі, які використовуються
      або зберігаються в HH , будь-які серверні Програми, з або без попереднього
      сповіщення Користувача.
    </p>
    <p>
      8.15 Ліцензіар та Ліцензіар має право надсилати Користувачу інформацію про
      розвиток HH  та його послуг, служб, а також рекламувати власну діяльність
      та послуги.
    </p>
    <p>
      8.16. Ліцензіар забезпечує конфіденційність та безпеку персональних даних
      при їх обробці Користувачем за допомогою HH шляхом:
    </p>
    <p>
      8.16.1. Надання доступу до HH через HTTPS або аналог, що забезпечує
      аналогічний рівень безпеки.
    </p>
    <p>
      8.16.2. Антивірусного захисту сервера (ів), на якому знаходиться HH, з
      використанням стандартного антивірусного програмного забезпечення, яке
      зазвичай використовується для захисту серверів цього типу.
    </p>
    <p>
      8.16.3. Використання брандмауера, який дозволяє використовувати і
      підтримувати лише мережевий трафік, необхідний для використання і
      підтримки HH.
    </p>
    <p>
      8.16.4. Реєстрації фактів авторизації Користувача та вихід з облікового
      запису з HH.
    </p>
    <div className="subtitle">9. ОБМЕЖЕННЯ НА ВИКОРИСТАННЯ ПРОГРАМИ</div>
    <p>9.1. Користувач не має права виконувати дії, які можуть спричинити:</p>
    <p>9.1.1. Несправність обладнання та мережі Ліцензіара.</p>
    <p>
      9.1.2. Переривання роботи Програми або обмеження можливості інших
      Користувачів користуватися Програмою.
    </p>
    <p>
      9.1.3. Несанкціонований доступ до Програми, а також комп'ютерні та
      мережеві ресурси Ліцензіара та Ліцензіара.
    </p>
    <p>
      9.1.4. Завдавання або загрозу заподіяння шкоди третім сторонам, у тому
      числі шляхом розміщення інформації та посилань на мережеві ресурси, зміст
      яких суперечить законодавству.
    </p>
    <p>
      9.2. Користувач не має права змінювати Програму, HH, їх дизайн та
      зовнішній вигляд.
    </p>
    <p>
      9.3. Ліцензіат самостійно забезпечує наявність обладнання, яке відповідає
      технічним вимогам щодо використання Програми та доступу до мережі
      Інтернет.
    </p>
    <p>
      9.4. Користувач гарантує, що має всі необхідні права над усіма даними,
      комп'ютерними Програмами або послугами, які використовуються ним у зв'язку
      з використанням Програми, і що такі дії не порушують прав третіх осіб.
    </p>
    <p>
      9.5. Користувач не може використовувати Програму будь-якими способами,
      крім зазначених у цій Угоді, він не може копіювати, продавати,
      перепродавати або отримувати доступ до неї, якщо Користувач не отримав
      попереднього письмового дозволу Ліцензіара.
    </p>
    <div className="subtitle">10. ОБМЕЖЕНА ГАРАНТІЯ ТА ВІДПОВІДАЛЬНІСТЬ</div>
    <p>
      10.1. Програма, служби, HH та її послуги, включаючи всі скрипти, додатки,
      контент та дизайн, надаються за принципом «як є». Ліцензіар не гарантує,
      що вся функціональність Програми буде відповідати очікуванням Ліцензіата,
      Користувачів і може бути застосована для їх конкретної мети. Ліцензіар
      також відмовляється від будь-якої гарантії, що HH, служби або послуги
      можуть бути або не можуть бути відповідними для конкретних цілей
      Користувача. Ліцензіар не може і не гарантує ніяких конкретних результатів
      від використання HH, послуг і (або) служб.
    </p>
    <p>
      10.2. Ліцензіар не ініціює або не контролює розміщення будь-якої
      інформації Користувачем під час використання Програми, HH, служб і послуг,
      не впливає на її зміст і цілісність, а також на час розміщення зазначених
      відомостей не знає і не може знати, чи порушує вона права та інтереси
      третіх осіб, що охороняються законом, міжнародними договорами та
      національним законодавством.
    </p>
    <p>
      10.3. Ліцензіат безпосередньо відповідає за зміст даних, створених і
      підтримуваних Ліцензіатом. Ліцензіар не здійснює попередній контроль за
      вмістом розміщеної та (або) інформації, що розповсюджується Ліцензіатом,
      але якщо розміщення і поширення такої інформації суперечить законодавству,
      то Ліцензіар має право блокувати або видаляти відповідний обліковий запис
      і дані без попереднього повідомлення.
    </p>
    <p>
      10.4. Ліцензіар не несе відповідальності перед Користувачем або будь-якою
      третьою стороною за будь-які непрямі, випадкові, ненавмисні збитки,
      включаючи втрату прибутку або даних, порушення честі, гідності або діловій
      репутації, що виникають внаслідок використання Програми, HH, послуг, вміст
      HH або інші матеріали, доступні Користувачу або іншим особам за допомогою
      HH, навіть якщо Користувач попереджав або вказував на можливість такої
      шкоди.
    </p>
    <p>
      10.5. Ліцензіар не несе відповідальності перед Користувачем за будь-яку
      шкоду, будь-яку втрату доходу, прибутку, інформації чи заощаджень,
      пов'язану з використанням або неможливістю використання Програми, HH,
      послуг, у тому числі у разі попереднього повідомлення Користувачем про
      можливість такої шкоди, або за будь-яким позовом від третьої особи.
    </p>
    <p>
      10.6. Ліцензіар забезпечує роботу і працездатність Програми, HH і бере на
      себе зобов'язання оперативно відновити свою працездатність у разі
      технічних збоїв і розривів. Ліцензіар не несе відповідальності за
      тимчасові невдачі та переривання Програми, HH та отриману втрату
      інформації у зв’язку з цим.
    </p>
    <p>
      10.7. Ліцензіар не несе відповідальності за будь-яку шкоду комп’ютеру
      Користувача або іншій особі, мобільним пристроям, будь-якому іншому
      обладнанню або програмному забезпеченню, викликаному або пов'язаному з
      скачуванням та (або) завантаженням матеріалів з HH або за допомогою
      посилань, розміщених у HH.
    </p>
    <p>
      10.8. Якщо під час використання Програми буде виявлено помилки, то
      Ліцензіар зробить кроки, щоб виправити їх якомога швидше. Точний час
      усунення конкретних помилок може бути визначений Ліцензіаром з урахуванням
      того, що Програма тісно співпрацює з іншими програмами стороннього
      програмного забезпечення, операційні системи та апаратні ресурси
      комп'ютерів Ліцензіата і продуктивність Програми, і час усунення
      несправностей не залежать виключно від Ліцензіара.
    </p>
    <p>
      10.9. У випадку, якщо Ліцензіат виконує дії, заборонені положеннями цієї
      Угоди, Ліцензіар має право, не пояснюючи причини та без будь-якого
      попереднього повідомлення Ліцензіата, вживати заходів для виявлення та
      запобігання цим порушенням.
    </p>
    <p>
      10.10. За порушення умов цієї Угоди Ліцензіат несе відповідальність,
      передбачену законодавством.
    </p>
    <div className="subtitle">
      11.УМОВИ ОБРОБКИ ТА ВИКОРИСТАННЯ ПЕРСОНАЛЬНИХ ДАНИХ
    </div>
    <p>
      11.1. Приймаючи умови цієї Угоди, Користувач відповідно до Закону України
      «Про захист персональних даних», діючи вільно, за його бажанням і в
      інтересах, висловлює свою згоду на надання своїх персональних даних,
      включаючи: прізвище, ім'я, по батькові, адресу електронної пошти,
      контактний номер телефону, дату народження, регіон, місто, організацію,
      посаду для їх обробки Ліцензіаром.
    </p>
    <p>11.2. Цілі обробки персональних даних Ліцензіаром:</p>
    <p>
      11.2.1. Надання Користувачу права користування Програмою, HH, послугами.
    </p>
    <p>
      11.2.2. Надсилання сповіщень Користувачу щодо використання Програми, HH,
      послуг.
    </p>
    <p>
      11.2.3. Підготовка та відправлення відповідей Ліцензіаром на запити
      Користувача, у тому числі, але не обмежуючись, зверненням до служби
      підтримки.
    </p>
    <p>
      11.2.4. Направлення інформації про діяльність, яку здійснюють Ліцензіар та
      Ліцензіар.
    </p>
    <p>11.2.5. Направлення інформації про продукти та послуги Ліцензіара.</p>
    <p>
      11.3. Перелік дій з персональними даними, на які погоджується Користувач:
      збір, систематизація, накопичення, зберігання, уточнення (оновлення,
      модифікація), використання, анонімізація, передача третім особам для
      цілей, зазначених в цій Угоді, а також будь-які інші дії, передбачені
      законодавством, як неавтоматизовані, так і автоматизовані.
    </p>
    <p>
      11.4. Ліцензіар бере на себе зобов'язання вжити всіх необхідних заходів,
      передбачених законодавством для захисту персональних даних Користувача від
      незаконного доступу або розголошення.
    </p>
    <p>
      11.5. Згода, зазначена в пунктах 11.1-11.3 цієї Угоди, діє до моменту її
      зняття Користувачем шляхом надсилання відповідного повідомлення на
      електронну адресу Ліцензіара або передачі такого повідомлення
      співробітникам Ліцензіара телефоном 
      <a target="_blank" href="https://house-hunter.info/info/contact">
        https://house-hunter.info/info/contact
      </a>
       . Користувач розуміє і погоджується, що якщо ця згода анульована, йому
      буде відмовлено в можливості використовувати частину або всі можливості
      Програми, HH і (або) Служб.
    </p>
    <p>
      11.6 Користувач погоджується отримати від Ліцензіара та його партнерів за
      допомогою телефонного номера, електронної адреси, яку він вказує під час
      реєстрації, рекламні та інформаційні повідомлення, SMS-повідомлення,
      Push-повідомлення про продукти, послуги та події, пов'язані з продуктами
      Ліцензіара.  
    </p>
    <div className="subtitle">12. ДІЯ, ЗМІНА ТА ПРИПИНЕННЯ ЦІЄЇ УГОДИ</div>
    <p>
      12.1. Ця Угода регулюється і тлумачиться відповідно до національного
      законодавства. Питання, не врегульовані цією Угодою, врегульовані
      відповідно до національного та міжнародного законодавства, якщо інший
      порядок визначення застосовного закону не передбачений Ліцензійним
      договором або нормами міжнародного права.
    </p>
    <p>
      12.2. Ця Угода набирає чинності для Користувача з дня його долучення до
      неї і залишається в силі на невизначений термін.
    </p>
    <p>
      12.3. Ця Угода поширюється на всі оновлення Програми, які надаються
      Ліцензіату, якщо не запропоновано ознайомити вас з новою Угодою або
      доповненням до цієї Угоди.
    </p>
    <p>
      12.4. Ліцензіар має право в односторонньому порядку змінити умови цієї
      Угоди, розмістивши змінений текст в мережі Інтернет на
      веб-сайті: https://house-hunter.info/info/terms . 
    </p>
    <p>
      12.5. Якщо з будь-якої причини один або кілька положень цієї Угоди визнані
      недійсними у встановленому порядку, це не впливає на дійсність або
      достовірність решти положень цієї Угоди.
    </p>
    <p>
      12.6. У разі виникнення будь-яких суперечок або розбіжностей, що виникають
      у зв'язку з виконанням цієї Угоди, Користувач і Ліцензіар докладають усіх
      зусиль для їх вирішення шляхом переговорів. У разі, якщо суперечки не
      будуть врегульовані шляхом переговорів, спори повинні вирішуватися в
      порядку, встановленому національним та міжнародним законодавством, за
      місцезнаходженням Ліцензіара, якщо існує ще один порядок визначення
      застосовного закону і (або) місце розгляду спору не передбаченого
      Ліцензійним договором, законодавством, нормами міжнародного права.
    </p>
    <p>
      12.7. Ліцензіар має право в односторонньому порядку розірвати цю Угоду, у
      разі порушення Користувачем умов цієї Угоди про використання Програми, HH,
      послуг.
    </p>
    <p>
      12.8. У разі припинення дії цієї Угоди будь-якою стороною та на будь-яких
      підставах Ліцензіат та Користувач зобов’язані припинити використання
      Програми, HH та послуг.
    </p>
    <div className="subtitle">13. ПОВІДОМЛЕННЯ</div>
    <p>
      13.1. HH має право надсилати електронні інформаційні повідомлення (далі -
      «сповіщення») про важливі події, що відбуваються всередині Програми або
      пов'язані з Користувачем  на електронну пошту Користувача або за номером
      телефону, вказаному в Програмі.  
    </p>
    <p>
      13.2. HH також дозволяється використовувати сповіщення для інформування
      Користувача про обмеження доступу до Програми у разі профілактичного
      обслуговування, порушення Користувачем цієї Угоди, зміни функціональності
      Програми, обсягу або умов надання Програми, включаючи внесення змін до
      цієї Угоди і обов'язкових документів.
    </p>
    <p>
      13.3. HH мають право на власний розсуд використовувати Програми сповіщень
      для розповсюдження реклами власних послуг та продуктів (послуг) третіх
      осіб.
    </p>
    <div className="subtitle">14. ІНФОРМАЦІЯ ПРО ЛІЦЕНЗІАРА</div>
    <p>
      Фізична особа підприємець Мицавка Юрій Юрійович, Україна, 82100 м.
      Дрогобич, вул. Горішня Брама 74. КОД РНОКПП: 3371613857.
    </p>
    <p>
      <b>
        Запити щодо умов цієї Угоди приймаються за електронною адресою
        Ліцензіара 
        <a target="_blank" href="https://house-hunter.info/info/contact">
          https://house-hunter.info/info/contact
        </a>
         . 
      </b>
    </p>
  </StyledPage>
);

const StyledPage = styled.div`
  height: 100vh;
  overflow: auto;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  a {
    color: var(--main-color);
  }
  .title {
    color: var(--main-color);
    font-family: Overpass;
    font-size: 32px;
    font-style: normal;
    font-weight: var(--font-weight-200);
    line-height: 118%;
    letter-spacing: 0.64px;
    text-align: center;
  }
  .subtitle {
    margin: 20px 0;
    color: var(--main-color);
    font-family: Overpass;
    font-size: 20px;
    font-style: normal;
    font-weight: var(--font-weight-100);
    line-height: 118%;
    letter-spacing: 0.4px;
  }
  p,
  li {
    margin-top: 7px;
    color: var(--white-color);
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-weight-100);
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 0 26px 0 0;
  }
  ul {
    padding-left: 40px;
    list-style: disc;
  }
`;
