import styled from "styled-components";

export const License = () => (
  <StyledPage>
    <div className="title">
      Ліцензійний договір-оферта на право використання програми для ЕОМ «House
      Hunter»
    </div>
    <p>
      <b>
        Цей документ є офіційною пропозицією фізична особа підприємець Мицавка
        Юрій Юрійович, Україна, 82100 м. Дрогобич, вул. Горішня Брама 74. КОД
        РНОКПП: 3371613857, укласти Ліцензійний договір на умовах, зазначених у
        цьому документі.
      </b>
    </p>
    <p>
      Цей Ліцензійний договір вважається укладеним з моменту прийняття акцепта
      Ліцензіатом. Згідно з умовами цього Ліцензійного договору, визначено, що
      ліцензійний платіж за цим Договором виплачується в порядку, на умовах та в
      строки, які визначаються цим Договором. Укладаючи цей Договір, Ліцензіат
      також повністю приймає умови Угоди користувача, текст якої розміщений
      Ліцензіаром на інтернет-сторінці: 
      <a target="_blank" href="https://house-hunter.info/info/terms">
        https://house-hunter.info/info/terms
      </a>
      .
    </p>
    <div className="subtitle">ТЕРМІНИ ТА ВИЗНАЧЕННЯ</div>
    <p>
      До цього документу та всіх відносин сторін, що виникають з цього
      документу, застосовуються такі терміни та визначення:
    </p>
    <p>
      <b>Публічна пропозиція/пропозиція -</b>  вміст цього документу з усіма
      додатками, доповненнями та доповненнями, викладеними на веб-сайті та
      доступними за адресою: 
      <a target="_blank" href="https://house-hunter.info/info/license">
        https://house-hunter.info/info/license
      </a>
    </p>
    <p>
      <b>Ліцензійний договір - </b> означає договір, укладений і виконаний
      Ліцензіатом і Ліцензіаром на основі цієї пропозиції разом з усіма
      обов'язковими документами пов'язаними з таким договором.
    </p>
    <p>
      <b>Сторони - </b>
      означають Ліцензіара і Ліцензіата.
    </p>
    <p>
      <b>Ліцензіар -</b> Фізична особа підприємець Мицавка Юрій Юрійович,
      Україна, 82100 м. Дрогобич, вул. Горішня Брама 74. КОД РНОКПП: 3371613857.
      Ліцензіат гарантує, що він має всі необхідні права та повноваження для
      надання ліцензії та виконання своїх зобов'язань за цією Угодою.
    </p>
    <p>
      <b>Ліцензіат  -</b> будь-яка фізична або юридична особа, яка має право
      користуватися Програмою HH в своїх інтересах відповідно до вимог
      законодавства України, Ліцензійного договору та Угоди користувача.
    </p>
    <p>
      <b>Ліцензія  -</b> це невиключне право непередаваного відкликання,
      обмежене право використання продукції у своїх функціональних цілях у
      параметрах дозволеного використання та у встановленому документацією
      відповідної продукції.
    </p>
    <p>
      <b>Веб-сайт -</b> означає будь-яку з автоматизованих інформаційних систем,
      доступних в мережі Інтернет за мережевими адресами в таких доменах
      (включаючи піддомени):{" "}
      <a target="_blank" href=" https://house-hunter.info/">
         https://house-hunter.info/
      </a>{" "}
      .
    </p>
    <p>
      <b>Обліковий запис користувача  - </b>
      запис в HH та (або) в Програмі, зберігаючи дані, що дозволяють
      ідентифікувати та авторизувати Ліцензіата, як користувача Програми.
    </p>
    <p>
      <b>Реєстрація -</b>дія, спрямована на створення облікового запису
      користувача. Реєстрація Користувача в HH безкоштовна і добровільна. При
      реєстрації в HH Користувач зобов'язаний забезпечити Ліцензіара необхідною
      достовірною і актуальною інформацією та заповнити спеціальний розділ
      особистого кабінету Ліцензіата. Користувач несе відповідальність за
      точність, актуальність, повноту і відповідність інформації, що надається
      при реєстрації та її чистоту від претензій третіх осіб. Розділ може бути
      заповнений фізичною особою, фізичною особою-підприємцем або юридичною
      особою.
    </p>
    <p>
      <b>Авторизація - </b> дозвіл, наданий Ліцензіаром на доступ до облікового
      запису Користувача, який реалізується шляхом аналізу інформації про
      обліковий запис у вигляді унікальної комбінації імені Користувача і
      пароля, введеної Користувачем на сторінці авторизації HH, і перевірки, що
      вона відповідає інформації, яка міститься в обліковому записі Користувача.
    </p>
    <p>
      <b>Особистий кабінет користувача  - </b> спеціалізований приватний розділ
      HH, який доступний Уповноваженому користувачу після реєстрації та
      авторизації, і призначений для здійснення Користувачем управління HH та
      його налаштування. Для входу в особистий кабінет Користувача
      використовується унікальне ім'я Користувача та пароль, отриманий
      Користувачем під час Реєстрації відповідно до пункту 8.4. Угоди
      користувача.
    </p>
    <p>
      <b>Ліцензійний платіж (роялті) -</b> переказ Ліцензіару ліцензійної плати
      за право користування програмою відповідно до обраного типу ліцензії.
      Оплата може здійснюватися будь-яким з доступних через інтерфейс програми
      способів. Вибір способу оплати здійснюється Ліцензіатом самостійно. Якщо
      оплата ліцензійного збору здійснюється через інтерфейс програми третьою
      стороною (користувачем), крім Ліцензіата, отримана плата зараховується на
      рахунок ID особистого рахунку Ліцензіата, визначеного третьою особою при
      оплаті. У цьому випадку Ліцензіат самостійно несе всі можливі наслідки,
      ризики і витрати, які можуть виникнути в цьому відношенні перед третьою
      стороною, претензії користувачів на відшкодування в цьому випадку
      Ліцензіаром не робляться.
    </p>
    <p>
      <b>Заключні документи  -</b> документи, що підтверджують передачу
      відповідної ліцензії Ліцензійним договором. Заключні документи формуються
      у вигляді електронного документа та надаються Ліцензіату через програмний
      інтерфейс, або за допомогою програмного забезпечення та технічних
      можливостей, які використовуються Ліцензіатом та Ліцензіаром. На письмове
      прохання Ліцензіата, заключні документи формуються у вигляді паперового
      документа, підписаного Ліцензіаром, і надсилаються поштою на вказану в
      відповідному запиті адресу.
    </p>
    <div className="subtitle">ПРЕДМЕТ ДОГОВОРУ</div>
    <p>
      Предметом цього Ліцензійного договору є передача Ліцензіаром невиключних
      прав використання результату інтелектуальної діяльності - програмного
      забезпечення для ЕОМ «House Hunter» - Ліцензія на прості (невиключні)
      умови ліцензії шляхом відтворення Програми (Підключення до Програми через
      мережу Інтернет і відкриття доступу до сервера Ліцензіара), виключно для
      власного використання Ліцензіатом без права Ліцензування третім особам.
    </p>
    <p>
      Доступ до сервера, вказаного у пункті 2.1. цього Договору надається
      (відкривається) Ліцензіаром протягом одного робочого дня з дня отримання
      винагороди, зазначеної в пункті 7.1. цього Договору, на банківський
      рахунок Ліцензіара.
    </p>
    <p>
      Перелік ліцензійної продукції HH, параметри дозволеного використання
      програми, термін дії ліцензії, ліцензійний платіж та інші умови надання та
      використання ліцензії визначаються згідно з цим Договором та обов’язковими
      документами.
    </p>
    <p>
      Територія використання продукції HH під ліцензією тільки на території
      України.
    </p>
    <p>
      Обліковий запис користувача створено на основі даних, наданих Ліцензіатом
      при реєстрації.
    </p>
    <div className="subtitle">ВИКЛЮЧНІ ПРАВА</div>
    <p>
      Програмне забезпечення для комп'ютера HH є результатом інтелектуальної
      діяльності Ліцензіара і охороняється законодавством про авторське право.
    </p>
    <p>
      Ліцензіар гарантує, що він має всі необхідні права на Програму та для
      надання її Ліцензіатам, включаючи документацію на програму.
    </p>
    <p>
      Алгоритми роботи Програми та її вихідні коди (у тому числі їх частини) є
      комерційною таємницею Ліцензіара. Будь-яке їх використання або
      використання програмного забезпечення з порушенням умов цього Договору
      розглядається як порушення прав Ліцензіара і є достатньою підставою для
      позбавлення Ліцензіата прав, наданих за цим Договором.
    </p>
    <p>
      Право на використання Програми надається лише Ліцензіату, без права
      передачі третім особам, лише в тій мірі, в якій зазначено в цьому
      Договорі, якщо Ліцензіар не надав письмової згоди на інше.
    </p>
    <p>
      Ліцензіат не має права власності на програму та її компоненти, а лише
      права на використання Програми та її компонентів відповідно до умов,
      визначених цим Договором.
    </p>
    <p>
      Ліцензіат не має права на користування товарними знаками та знаками
      обслуговування Ліцензіара та (або) його партнерів.
    </p>
    <p>
      За жодних обставин Ліцензіат не може видаляти або вносити незначні
      відомості про авторські права, права на товарний знак, знаки
      обслуговування, патенти, вказані у Програмі.
    </p>
    <p>
      Ліцензіат не може копіювати, розповсюджувати Програму та її компоненти у
      будь-якій формі, у тому числі у вигляді вихідного коду, у тому числі
      надавати в оренду, передавати для безоплатного користування.
    </p>
    <p>
      Ліцензіат не може жодним чином використовувати Програму, якщо це
      суперечить або призводить до порушення цього Договору або вимог
      національного та міжнародного законодавства.
    </p>
    <div className="subtitle">УМОВИ ВИКОРИСТАННЯ ТА ОБСЯГ НАДАНИХ ПРАВ</div>
    <p>Ліцензіат може використовувати програму таким чином:</p>
    <p>
      <ul>
        <li>
          Доступ до сервера Ліцензіара для використання Програми, 24 години на
          добу (крім часу профілактичної роботи) та відтворення графічної
          частини (робочого інтерфейсу) Програми на екрані персонального
          комп'ютера (іншого кінцевого пристрою, передбаченого Програмою
          документації) Ліцензіата.
        </li>
        <li>
          Використання всіх платних функцій Програми згідно з описом вибраного
          типу ліцензії.
        </li>
        <li>
          Відтворення документації, що стосується Програми для особистого
          користування.
        </li>
      </ul>
    </p>
    <p>
      Ліцензіар не надає послуг зв'язку Ліцензіату, не організовує для нього
      можливості доступу до інформаційних систем інформаційно-телекомунікаційних
      мереж, у тому числі мережі Інтернет, і не здійснює діяльності з прийому,
      обробки, зберігання, передачі, доставки телекомунікаційних повідомлень.
    </p>
    <div className="subtitle">ПРАВА ТА ОБОВЯЗКИ СТОРІН ДОГОВОРУ</div>
    <p>Ліцензіар повинен:</p>
    <p>
      <ul>
        <li>
          Переконатись, що сервер доступний 24 години на добу (за винятком часу
          профілактичної роботи) для використання програми.
        </li>
        <li>
          Протягом одного робочого дня після отримання винагороди, передбаченої
          в пункті 7.1. цього Договору, на банківський рахунок Ліцензіара, надає
          Ліцензіату можливість (відкритий доступ) користуватися програмою.
        </li>
        <li>
          <b>
            Забезпечити конфіденційність даних, розміщених Ліцензіатом в
            Програмі на весь період його перебування на сервері Ліцензіара та
            Ліцензіара.
          </b>
        </li>
        <li>
          Надати Ліцензіату, який уклав окремий договір, що забезпечує доступ до
          сервісу (сервісів), список яких розміщений Ліцензіаром в мережі
          Інтернет на сторінці {" "}
          <a target="_blank" href="https://house-hunter.info/info/terms">
            https://house-hunter.info/info/terms
          </a>
          , можливість доступу до відповідних сервісу (сервісів) з використанням
          інтерфейсу Програми.
        </li>
        <li>На прохання Ліцензіата надіслати заключні документи.</li>
        <li>Виконувати інші зобов’язання передбачені Договором.</li>
      </ul>
    </p>
    <p>Ліцензіар має право:</p>
    <p>
      <ul>
        <li>
          Внести зміни до Програми, не повідомляючи Ліцензіату у будь-який час і
          з будь-яких причин, у тому числі, але не обмежуючись, з метою
          задоволення потреб інших ліцензіатів, вимог конкурентоспроможності,
          або з метою дотримання вимог нормативних актів. Ліцензіар залишає за
          собою право додавати нові властивості та функціональні можливості
          Програми або вилучати з Програми існуючі властивості та функціональні
          можливості.
        </li>
        <li>
          Заблокувати доступ Ліцензіата до Програми, якщо Ліцензіат порушує
          умови цього Договору або Угоди користувача, текст якої публікується
          Ліцензіаром в мережі Інтернет на веб-сайті:
          <a target="_blank" href="https://house-hunter.info/terms">
            https://house-hunter.info/terms
          </a>
          .
        </li>
      </ul>
    </p>
    <p>Ліцензіат повинен:</p>
    <p>
      <li>
        Не робити дій або інших спроб, спрямованих на копіювання, модифікацію,
        декомпізацію (перетворення коду автомобіля в вихідний), декомпізацію
        (аналіз і дослідження автомобільного коду) Програми.
      </li>
      <li>
        Суворо дотримуватись умов цього Договору та Угоди користувача, текст
        якої публікується Ліцензіаром в мережі Інтернет на
        сторінці: https://house-hunter.info/terms.
      </li>
      <li>
        Не вчиняти заходів щодо Програми, які порушують національні та (або)
        міжнародні норми щодо авторського права та використання програмного
        забезпечення.
      </li>
      <li>
        Надати Ліцензіару всю інформацію та документи, необхідні для того, щоб
        Ліцензіар виконав свої зобов'язання за цим Договором.
      </li>
      <li>Виконувати інші зобов’язання передбачені Договором.</li>
    </p>
    <p>Ліцензіат має право:</p>
    <p>
      <ul>
        <li>
          На доступ до сервера Ліцензіара для використання програми 24 години на
          добу (крім часу роботи з технічного обслуговування).
        </li>
        <li>
          Використовувати всі платні функціональні можливості Програми
          відповідно до опису обраного типу ліцензії у спосіб, що не порушує
          умов цього Договору, Угоди користувача, національного та міжнародного
          законодавства.
        </li>
        <li>
          Не надсилати звіти про використання програмного забезпечення до
          Ліцензіара.
        </li>
        <li>
          На доступ, використання програмного забезпечення (інтерфейс, що
          надається Ліцензіаром) послуги, перераховані Ліцензіаром в мережі
          Інтернет на сторінці: 
          <a
            target="_blank"
            href="https://house-hunter.info/info/service-agreement"
          >
            https://house-hunter.info/info/service-agreement
          </a>
          . Порядок надання доступу до цих послуг встановлюється окремою угодою.
        </li>
      </ul>
    </p>
    <p>Ліцензіат гарантує, що:</p>
    <p>
      <ul>
        <li>
          При обробці персональних даних Ліцензіата дотримується всіх прав
          суб'єктів персональних даних, передбачених законодавством у сфері
          захисту персональних даних.
        </li>
        <li>
          Ліцензіат одержав згоду суб'єктів персональних даних на обробку їх
          персональних даних.
        </li>
      </ul>
    </p>
    <div className="subtitle">СТРОК ДІЇ ДОГОВОРУ</div>
    <p>
      Цей Договір набуває чинності з дня прийняття умов цього Договору,
      підтверджених оплатою ліцензійного платежу, на підставі виставленого
      Ліцензіаром на адресу Ліцензіата рахунку, і діє на строк, встановлений
      ліцензією та визначений у виставленому Ліцензіаром на адресу Ліцензіата
      рахунку, а також автоматично подовжується на новий строк при оплаті кожної
      наступної ліцензії.
    </p>
    <p>
      Датою укладення цього Договору є день отримання ліцензійного платежу за
      рахунком, виставленим Ліцензіаром на адресу Ліцензіата, на банківський
      рахунок Ліцензіара.
    </p>
    <p>
      У випадку, якщо Ліцензіат порушує умови цього Договору, Угоду користувача,
      або Політики конфіденційності, текст якої розміщується Ліцензіаром в
      мережі Інтернет на сторінці: https://house-hunter.info/info/privacy, а
      також національне та міжнародне законодавство, Ліцензіар має право
      достроково припинити дію цього Договору і негайно заблокувати доступ до
      Програми (включаючи сервер Ліцензіара) без попереднього повідомлення
      Лцензіата.
    </p>
    <div className="subtitle">
      ЛІЦЕНЗІЙНИЙ ПЛАТІЖ (РОЯЛТІ) ТА ПОРЯДОК ЙОГО СПЛАТИ
    </div>
    <p>
      Ліцензіат сплачує ліцензійний платіж Ліцензіару за право користування
      програмою відповідно до обраного типу ліцензії в розмірі, визначеному в
      рахунку, відповідно до порядку, передбаченого в пункті 6.1. цього
      Договору.
    </p>
    <p>
      Ліцензіат здійснює оплату рахунку, виставленого Ліцензіаром у порядку,
      передбаченому в пункті 6.1. цього Договору, шляхом одноразового
      перерахування протягом визначеного в такому рахунку терміну, всієї суми
      ліцензійного платежу, зазначеного в такому рахунку, на банківський рахунок
      Ліцензіара за реквізитами, зазначеними в такому рахунку.
    </p>
    <p>
      <ul>
        <li>
          У випадку, якщо оплата здійснюється банківською карткою, дія цього
          Договору буде продовжена автоматично, якщо Ліцензіат не оголошує про
          розірвання цього пункту Договору, в порядку і строки, передбачені
          пунктом 7.2.2. цього Договору. У разі автоматичного продовження дії
          цього Договору ліцензійний платіж автоматично буде списано Ліцензіаром
          з банківського (карткового) рахунку Ліцензіата, що використовується
          для оплати. У цьому випадку розмір винагороди буде визначений на
          підставі вартості відповідного типу ліцензії на дату списання (пункт
          7.4. цього Договору).
        </li>
        <li>
          Ліцензіат має право у будь-який час протягом строку дії цього
          Договору, але не пізніше 1 години до закінчення терміну, встановленого
          ліцензією (пункт 6.1. цього Договору) оголосити про припинення дії
          пункту 7.2.1. цього Договору за допомогою відповідної функції
          (спеціального інтерфейсу) облікового запису Користувача.
        </li>
        <li>
          Ліцензіар не бере на себе жодних зобов’язань надсилати до Ліцензіата
          будь-якими засобами зв’язку (у тому числі, не обмежуючись: електронна
          пошта, пошта, телефон, факс, SMS) про майбутнє автоматичне продовження
          дії цього Договору; не несе і не може нести ніякої відповідальності за
          пропущення Ліцензіатом терміну дії оголошення про припинення дії
          пункту 7.2.1. цього Договору, встановленого пунктом 7.2.2 цього
          Ддоговору.
        </li>
      </ul>
    </p>
    <p>
      Вартість права на користування програмою (ліцензійна плата, зазначена в
      пункті 7.1. цього Договору) не оподатковується.
    </p>
    <p>
      Ліцензіар має право в односторонньому порядку змінювати ліцензійний платіж
      за право користування Програмою при продовженні цього Договору відповідно
      до існуючих типів ліцензій.
    </p>
    <p>
      У разі, якщо протягом п’яти календарних днів з моменту закінчення терміну
      дії ліцензії, Ліцензіар не отримав від Ліцензіата претензій, що стосуються
      сфери наданих прав, що невиключне право на використання Програми, що
      надається Договором в повному обсязі та належним чином виконано.
    </p>
    <p>
      Дострокове припинення дії цього Договору не є підставою для повернення
      ліцензійної плати.
    </p>
    <div className="subtitle">ВІДПОВІДАЛЬНІСТЬ СТОРІН</div>
    <p>
      Сторони несуть відповідальність за невиконання або неналежне виконання
      зобов'язань за цим Договором відповідно до законодавства.
    </p>
    <p>
      Ліцензіар не несе відповідальності за неможливість використання Програми з
      незалежних від Ліцензіара причин.
    </p>
    <p>
      Ліцензіар не несе відповідальності за зміст, точність і повноту
      інформації, отриманої Ліцензіатом шляхом використання Програми.
    </p>
    <p>
      Ліцензіар не несе відповідальності за дії та рішення Ліцензіата, прийняті
      на підставі інформації, отриманої Ліцензіатом при використанні Програми,
      їх наслідків, а також прямих і непрямих збитків, у тому числі втрати
      прибутку, що випливають із застосування Програми.
    </p>
    <div className="subtitle">ФОРС-МАЖОР (ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ)</div>
    <p>
      Сторони домовились, що під форс-мажорними обставинами (обставинами
      непереборної сили) вони розуміють надзвичайні та невідворотні за даних
      умов обставини, які об’єктивно унеможливлюють виконання зобов’язань,
      передбачених умовами цього Договору.
    </p>
    <p>Дія таких обставин може бути викликана:</p>
    <p>
      <ul>
        <li>
          винятковими погодними умовами і стихійними лихами (наприклад, але не
          виключно – ураган, буревій, сильний шторм, циклон, торнадо, повінь,
          нагромадження снігу, ожеледь, град, заморозки, замерзання моря,
          проток, портів, перевалів, землетрус, пожежа, посуха, блискавка,
          просідання і зсув ґрунту, епідемія тощо);
        </li>
        <li>
          непередбачуваними діями/бездіяльністю сторони, що не є Стороною
          Договору, та/або які відбуваються незалежно від волі і бажання Сторони
          Договору (наприклад, але не виключно - страйк, локаут, дія іноземного
          ворога, загроза війни, оголошена та неоголошена війна, збройний
          конфлікт або серйозна погроза такого конфлікту, військові дії, ворожа
          атака, військове вторгнення, введення комендантської години, збурення,
          безлади, терористичний акт, диверсії, піратства, блокада, революція,
          заколот, повстання, масові заворушення, громадська демонстрація,
          загальна військова мобілізація, протиправні дії третіх осіб, аварія,
          пожежа, вибух, примусове вилучення, експропріація, реквізиція,
          захоплення підприємств тощо);
        </li>
        <li>
          непередбачуваними діями/бездіяльністю сторони, що не є Стороною
          Договору, та/або які відбуваються незалежно від волі і бажання Сторони
          Договору (наприклад, але не виключно - страйк, локаут, дія іноземного
          ворога, загроза війни, оголошена та неоголошена війна, збройний
          конфлікт або серйозна погроза такого конфлікту, військові дії, ворожа
          атака, військове вторгнення, введення комендантської години, збурення,
          безлади, терористичний акт, диверсії, піратства, блокада, революція,
          заколот, повстання, масові заворушення, громадська демонстрація,
          загальна військова мобілізація, протиправні дії третіх осіб, аварія,
          пожежа, вибух, примусове вилучення, експропріація, реквізиція,
          захоплення підприємств тощо);
        </li>
        <li>
          умовами, регламентованими відповідними рішеннями та актами органів
          державної влади та місцевого самоврядування, а також пов’язаними з
          ліквідацією наслідків, викликаних винятковими погодними умовами і
          непередбаченими ситуаціями (наприклад, але не виключно – ембарго,
          закриття сухопутних чи водних шляхів, заборона (обмеження)
          експорту/імпорту, тривалі перерви в роботі транспорту тощо).
        </li>
      </ul>
    </p>
    <p>
      Сторона, для якої склались форс-мажорні обставини (обставини непереборної
      сили), зобов’язана не пізніше десяти робочих днів з дати їх настання
      письмово (шляхом направлення цінного листа з описом вкладення та
      повідомленням про вручення) інформувати іншу Сторону про настання таких
      обставин та про їх наслідки. Разом з письмовим повідомленням така Сторона
      зобов’язана надати іншій Стороні документ, виданий Торгово-промисловою
      палатою України або його територіальним відділенням, а також іншим
      уповноваженим органом, яким засвідчене настання форс-мажорних обставин
      (обставин непереборної сили). Аналогічні умови застосовуються Стороною в
      разі припинення дії форс-мажорних обставин (обставин непереборної сили) та
      їх наслідків.
    </p>
    <p>
      Неповідомлення/несвоєчасне повідомлення Стороною, для якої склались
      форс-мажорні обставини (обставини непереборної сили), іншу Сторону про їх
      настання або припинення веде до втрати права Сторони посилатись на такі
      обставини як на підставу, що звільняє її від відповідальності за
      невиконання/несвоєчасне виконання зобов’язань за цим Договором.
    </p>
    <p>
      В разі існування форс-мажорних обставин (обставин непереборної сили) понад
      три місяці, будь-яка Сторона вправі в односторонньому порядку відмовитися
      від цього Договору. В такому разі Сторона повинна письмово (шляхом
      направлення цінного листа з описом вкладення та повідомленням про
      вручення) проінформувати іншу Сторону про свою відмову від Договору. Разом
      з письмовим повідомленням така Сторона зобов’язана надати іншій Стороні
      документ, виданий Торгово-промисловою палатою України, яким засвідчене
      існування форс-мажорних обставин (обставин непереборної сили) понад три
      місяці. У цьому випадку Договір вважається розірваним з дня отримання
      Стороною повідомлення про відмову іншої Сторони від Договору або з п’ятого
      календарного дня з дати направлення Стороною повідомлення про відмову від
      Договору залежно від того, яка подія станеться раніше.
    </p>
    <div className="subtitle">КОНФІДЕНЦІЙНІСТЬ</div>
    <p>
      Сторони погоджуються зберігати конфіденційність всіх умов Договору та
      всієї інформації, отриманої однією стороною від іншої сторони при
      укладанні та виконанні цього Договору («Confidential Information») і не
      розголошувати отриману інформацію для громадськості або в іншому випадку
      надавати таку інформацію будь-якій третій стороні без попередньої
      письмової згоди сторони, яка надала таку інформацію.
    </p>
    <p>
      Кожна сторона буде вживати всіх необхідних заходів для захисту
      конфіденційної інформації з принаймні таким же ступенем догляду, як і
      заявка на захист власної конфіденційної інформації. Доступ до
      конфіденційної інформації буде надаватися тільки працівникам кожної з
      сторін, які повинні знати її для виконання своїх обов'язків з виконання
      цього Договору. Кожна зі сторін повинна забезпечити виконання такими
      співробітниками одних і тих самих зобов'язань щодо захисту конфіденційної
      інформації, що передбачено цією пропозицією щодо сторін.
    </p>
    <p>
      Персональна інформація Ліцензіата та його клієнтів обробляється
      Ліцензіаром відповідно до політики конфіденційності.
    </p>
    <p>
      Зобов'язання щодо захисту конфіденційної інформації залишаються в силі
      протягом періоду дії цього Договору та протягом 5 (п'яти) років після його
      закінчення, якщо інше не узгоджено сторонами.
    </p>
    <div className="subtitle">ЗАКЛЮЧНІ ПОЛОЖЕННЯ</div>
    <p>
      У всіх інших питаннях, прямо не врегульованих цим Договором, відносини, що
      виникають між Ліцензіаром та Ліцензіатом, у зв'язку з використанням
      Програми, регулюються Угодою користувача, Політики конфіденційності,
      національним законодавством та нормами міжнародного права.
    </p>
    <p>
      Ліцензіар має право в односторонньому порядку змінювати умови цього
      Договору шляхом публікації нового тексту Договору в мережі Інтернет на
      сторінці: 
      <a target="_blank" href="https://house-hunter.info/info/license">
        https://house-hunter.info/info/license
      </a>
      . Договір, змінений таким чином, поширюється на відносини між Ліцензіаром
      та Ліцензіатом, що виникають після дати його публікації, якщо тільки
      договір між Ліцензіаром та Ліцензіатом не передбачає інше.
    </p>
    <p>
      На вимогу Ліцензіата цей Договір може бути оформлений також у вигляді
      одного документа, підписаного сторонами. Порядок виконання Договору у
      зазначеному вигляді встановлюється Угодою користувача.
    </p>
    <p>
      Одностороння відмова Ліцензіата від цього Договору не допускається.
      Добровільне припинення Ліцензіатом використання Програми (включаючи
      видалення облікового запису користувача) не визнається відмовою Ліцензіата
      від цього Договору (незалежно від факту повідомлення про це Ліцензіара) і
      не тягне за собою зобов'язань Ліцензіара на перерахування і (або)
      повернення ліцензійного платежу, виплаченої Ліцензіатом у спосіб,
      передбачений пунктами 7.1-7.5 цього Договору.
    </p>
    <p>
      Ліцензія автоматично припиняє свою дію у разі розірвання або припинення
      дії Договору.
    </p>
    <div className="subtitle">ІНФОРМАЦІЯ ПРО ЛІЦЕНЗІАРА</div>
    <p>
      <b>
        Фізична особа підприємець Мицавка Юрій Юрійович, Україна, 82100 м.
        Дрогобич, вул. Горішня Брама 74. КОД РНОКПП: 3371613857
      </b>
    </p>
    <p>
      <b>Запити щодо умов цієї Угоди приймаються за електронною адресою</b>
    </p>
  </StyledPage>
);

const StyledPage = styled.div`
  height: 100vh;
  overflow: auto;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  a {
    color: var(--main-color);
  }
  .title {
    color: var(--main-color);
    font-family: Overpass;
    font-size: 32px;
    font-style: normal;
    font-weight: var(--font-weight-200);
    line-height: 118%;
    letter-spacing: 0.64px;
    text-align: center;
  }
  .subtitle {
    margin: 20px 0;
    color: var(--main-color);
    font-family: Overpass;
    font-size: 20px;
    font-style: normal;
    font-weight: var(--font-weight-100);
    line-height: 118%;
    letter-spacing: 0.4px;
  }
  p,
  li {
    margin-top: 7px;
    color: var(--white-color);
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: var(--font-weight-100);
    line-height: normal;
    letter-spacing: 0.28px;
    padding: 0 26px 0 0;
  }
  ul {
    padding-left: 40px;
    list-style: disc;
  }
`;
